import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';

import Order from 'organisms/Order/Order';
import styles from './OrderPage.module.scss';

export const ORDER_PAGE_QUERY = graphql`
  query OrderPageQuery {
    deliveryAd: file(
      relativePath: { eq: "delivery/thamarai-delivery-launch.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  deliveryAd: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

export interface Props {}

const OrderPage: React.FC<Props> = () => {
  const { deliveryAd } = useStaticQuery<Data>(ORDER_PAGE_QUERY);

  return (
    <div className={styles[`OrderPage`]}>
      <Order />
      <Img
        fluid={deliveryAd.childImageSharp.fluid}
        alt="Food delivery launch banner"
        className={styles[`OrderPage__launch-banner`]}
      />
    </div>
  );
};

export default OrderPage;
