import React from 'react';

import Layout from 'molecules/Layout';
import OrderPage from 'organisms/OrderPage';

export interface Props {}

const MyOrderPage: React.FC<Props> = () => {
  return (
    <Layout
      footerIcon={() => null}
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/bestellen"
          canonical="/bestellen"
          lang="de-DE"
          title="Bestellen"
          description="Das THAMARAI Restaurant Heilbronn ist aktuell ein komplett kontkaktloser Liefer- und Abholservice für Spezialitäten der tamilisch indischen Küche in Heilbronn."
        />
      )}
      header={() => (
        <Layout.Header
          title="Online Bestellen"
          subtitle="Wählen Sie zwischen Lieferdienst und Abholservice"
          abstract="Das THAMARAI Restaurant Heilbronn ist aktuell ein komplett kontkaktloser Liefer- und Abholservice für Spezialitäten der tamilisch indischen Küche in Heilbronn. Der Bestellvorgang ist auf unserer Webseite mit wenigen Klicks erledigt. Wählen Sie zwischen Kothu Roti, Butter Chicken oder dem Panir Butter Masala. Geben Sie den Schärfegrad an und bezahlen Sie ganz bequem online. Unsere TakeAway Abholstation auf dem THAMARAI Parkplatz ermöglicht eine Übergabe ohne persönlichen Kontakt! Wir liefern unser Essen auch gerne zu Ihnen nach Hause im Umkreis von 5 km. Wir wünschen einen guten Appetit!"
          alt="THAMARAI Restaurant Heilbronn Logo"
        />
      )}
    >
      <OrderPage />
    </Layout>
  );
};

export default MyOrderPage;
